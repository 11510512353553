<template>
  <div class="Grid-row">
    <div class="Grid-column-12">
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Username</th>
            <th>Avatar</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="user in bannedUsers" :key="user.id">
            <td>{{ user.name }}</td>
            <td>{{ user.username }}</td>
            <td>
              <img
                v-if="user.avatar_url"
                :src="user.avatar_url"
                width="100"
                height="100"
              />
            </td>
            <td>
              <button class="button button-success" @click="unbanUser(user)">
                Unban user
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <infinite-loading
      ref="infiniteLoading"
      @if="bannedUsersNotLoaded"
      @infinite="infiniteHandler"
    >
      <span slot="no-results">All banned users loaded.</span>
      <span slot="no-more">All banned users loaded.</span>
    </infinite-loading>
  </div>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading';

export default {
  name: 'BannedUsersList',
  components: {
    InfiniteLoading,
  },
  computed: {
    bannedUsers: {
      get() {
        return this.$store.getters.bannedUsers;
      },
    },
    bannedUsersNotLoaded: {
      get() {
        return this.$store.getters.bannedUsersNotLoaded;
      },
    },
  },
  methods: {
    infiniteHandler(state) {
      this.$store.dispatch('getBannedUsers').then(() => {
        if (this.bannedUsersNotLoaded) {
          state.loaded();
        } else {
          state.complete();
        }
      });
    },
    unbanUser(user) {
      this.$store.dispatch('unbanUser', { userId: user.id });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/stylesheets/components/ban-tools/community/SpacesToBan.scss';
</style>
